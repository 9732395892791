<template>
    <div>
        <b-overlay :show="loadGraph" rounded="sm">

            <div class="container_site">
                
                <div class="container_dimension_mobile__card_segments--mobile" :style="{'height': Graph.type == 10 ? '460px' : 'auto'}">
                    <div class="container_dimension_mobile__card_segment--title_segment">
                        {{Graph.name || Mapa.name}}
                    </div>
                    <div class="container_dimension_mobile__card_segment--description_segment">
                        {{Graph.description || Mapa.description}}
                    </div>
                    <Graphs
                        :type="Graph"
                        v-if="$route.query.Type == 'Graph'"
                    />
                    <Maps 
                        :type="Mapa"
                        
                    />
                </div>
                <div class="container_btn_actions mb-5">
                    <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
                        <img src="@/assets/img/icono-regresar.svg" alt="">
                        <p>Regresar</p>
                    </div>
                    <div class="container_btn_actions__btn_home" @click="$router.push({name: 'Home'})">
                        <img src="@/assets/img/icono-inicio.svg" alt="">
                        <p>Inicio</p>
                    </div>
                </div>
                
            </div>
        </b-overlay>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id:this.$route.query.id,
            loadGraph: true,
            Graph: null,
            Mapa: {
                name:'',
                description:'',
                url:''
            }
        }
    },
    methods:{
        getSegments(){
            this.$http.get('architecture_tags/dimension/'+this.id).then(response =>{
                
                this.loadGraph = false;
                if (this.$route.query.Type == 'Graph') {
                    console.log('consulta', response.body)
                    response.body.Segments.forEach(Segment => {
                         
                        Segment.tags.categories.forEach(Category => {
                            if (Category.id == this.$route.query.Category) {
                                this.Graph = Category.graphs.tables[this.$route.query.keyGraph];
                            }
                        });
                    });
                }else{
                    response.body.Segments.forEach(Segment => {

                        Segment.tags.categories.forEach(Category => {
                            if (Category.id == this.$route.query.Category) {
                                this.Mapa = Category.graphs.maps[this.$route.query.keyGraph];
                                console.log(this.Mapa)
                            }
                        });
                    });
                }
                
                
            }).catch(error => {
                Console.Log('Error al consultar los segmentos: ', error)
            })
        }
    },
    created() {
        this.getSegments();
         console.log('id', this.id)
         console.log('Mapa', this.Mapa)
         console.log('Graph', this.Graph)
         console.log('type', this.$route.query.Type )

    }, 
}
</script>